import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Info from "../components/Info"
import Projects from "../components/Projects"

const Home = () => {
  const query = useStaticQuery(graphql`
    {
      prismicHome {
        data {
          title {
            text
          }
          meta_title
          meta_image {
            url
          }
          meta_description
        }
      }
      allPrismicProject(sort: { fields: last_publication_date, order: DESC }) {
        edges {
          node {
            url
            data {
              project_title {
                text
              }
              cover_image {
                url(imgixParams: { w: 800, h: 600, fit: "crop" })
              }
            }
          }
        }
      }
    }
  `)

  const home = query.prismicHome.data
  const projects = query.allPrismicProject.edges
  console.log(home, projects)
  if (!home || !projects) return null

  return (
    <Layout>
      <Seo
        title={
          home.meta_title ? home.meta_title : "Senior Designer — Portland, OR"
        }
        description={
          home.meta_description
            ? home.meta_description
            : "Senior Designer, Portland OR"
        }
      />

      <Info />
      <Projects data={projects} />
    </Layout>
  )
}

export default Home
