import { Link } from "gatsby"
import React from "react"

const Projects = ({ data }) => {
  return (
    <section className="projects">
      {data.map((project, ind) => {
        return <Project project={project.node} />
      })}
    </section>
  )
}

const Project = ({ project }) => {
  return (
    <div className="projects__project">
      <Link to={project.url}>
        <img
          src={project.data.cover_image.url}
          alt={project.data.project_title.text}
        />
        <h3>{project.data.project_title.text}</h3>
      </Link>
    </div>
  )
}

export default Projects
